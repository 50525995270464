import { PointsMaterial, Color, BufferGeometry, Float32BufferAttribute, Points, Box3, BufferAttribute, LinearSRGBColorSpace } from "three"
import { randomUnitVector, randomInRange } from "../system/helpers";

const defaultMainColor = new Color('white');
const defaultSecondaryColor = new Color('black');

class NodeClusters {
    numOfGalaxies;
    galaxies;
    centerPoint;
    radius;
    dimmingFactor;
    galaxyPositions = [];
    galaxyColors = [];
    galaxy
    nodeGeometry;
    nodeMaterial;
    galaxyAlphas = [];
    mainColor;
    secondaryColor;

    constructor(geometry, centerPoint, numOfGalaxies = 50,
        radius = 1, dimmingFactor = 1,
        mainColor = defaultMainColor,
        secondaryColor = defaultSecondaryColor
    ) {
        this.centerPoint = centerPoint;
        this.numOfGalaxies = numOfGalaxies;
        this.radius = radius;
        this.dimmingFactor = dimmingFactor;
        this.mainColor = mainColor;
        this.secondaryColor = secondaryColor;
        this.generateGalaxies(geometry);
    }


    generateGalaxies(geometry) {
        this.nodeGeometry = new BufferGeometry();
        const color = new Color('white');
        for (let i = 0; i < this.numOfGalaxies; i++) {
            let distanceFromCenter = randomInRange(0, this.radius)
            let normalizedDistance = distanceFromCenter / this.radius;
            let newGalaxyPosition = randomUnitVector();
            let recripcalNormalizedDistance = (1 - normalizedDistance) * this.dimmingFactor;
            newGalaxyPosition = newGalaxyPosition.multiplyScalar(distanceFromCenter);
            newGalaxyPosition = newGalaxyPosition.add(this.centerPoint);
            if (geometry.containsPoint(newGalaxyPosition)) {
                this.galaxyPositions.push(newGalaxyPosition.x, newGalaxyPosition.y, newGalaxyPosition.z);
                const newColor = new Color().lerpColors(this.mainColor, this.secondaryColor, recripcalNormalizedDistance)
                this.galaxyColors.push(newColor.r, newColor.g, newColor.b);
                this.galaxyAlphas.push(recripcalNormalizedDistance);
            }
        }

        this.nodeGeometry.setAttribute('position', new Float32BufferAttribute(this.galaxyPositions, 3));
        this.nodeGeometry.setAttribute('color', new Float32BufferAttribute(this.galaxyColors, 3));
        this.nodeGeometry.setAttribute('alpha', new Float32BufferAttribute(this.galaxyAlphas,1));
        this.nodeMaterial = new PointsMaterial({ size: 0.7, vertexColors: true, transparent: true, depthWrite: true });
        this.galaxies = new Points(this.nodeGeometry, this.nodeMaterials);
    }
}
export { NodeClusters }