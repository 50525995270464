import { UniformsLib, UniformsUtils, ShaderLib, ShaderMaterial } from "three";

const galaxyVertexShader = /*glsl*/`
#include <fog_pars_vertex>

attribute float alpha;
varying float _alpha;

attribute vec3 color;
out vec3 _color;

uniform float scale;
uniform float media_adjustment;


void main(){
    
    _alpha = alpha;
    _color = color;
    vec4 localPosition = vec4(position,1.0);
    vec4 worldPosition = modelMatrix * localPosition;
    vec4 viewPosition = viewMatrix * worldPosition;
    vec4 mvPosition = viewPosition;
    gl_PointSize = media_adjustment * alpha;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    #include <fog_vertex>
}
`;

const galaxyFragmentShader = /*glsl*/`
#include <fog_pars_fragment>
    varying float _alpha;
    varying vec3 _color;
void main(){

    gl_FragColor = vec4(_color, _alpha);
    #include <fog_fragment>
}   
`;

const shaderPoint = ShaderLib.points;
const adjustedSize = window.devicePixelRatio > 1 ? 4.0 : 1.5;
const _uniforms = {
    media_adjustment: { value: adjustedSize }
}
const uniforms = UniformsUtils.merge([UniformsLib['fog'], UniformsUtils.clone(shaderPoint.uniforms), _uniforms]);
uniforms.scale.value = 350;
const galaxyMat = new ShaderMaterial({
    uniforms: uniforms,
    defines: {
        USE_SIZEATTENUATION: ""
    },
    vertexShader: galaxyVertexShader,
    fragmentShader: galaxyFragmentShader,
    fog: true,
    transparent: true,
})



export { galaxyFragmentShader, galaxyVertexShader, galaxyMat }