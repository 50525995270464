<template>
  <div class="home">
    <home-canvas></home-canvas>
  </div>
</template>

<script>
import HomeCanvas from "@/components/HomeCanvas.vue"
export default {
  components: {
    HomeCanvas
  }
}
</script>

<style scoped></style>
