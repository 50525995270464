import { Clock, UniformsLib, UniformsUtils, ShaderLib, ShaderMaterial } from "three";
//mvPosition = position ;  
//_radius = mod(_radius, 50.0);

const introPointVertexShader = /*glsl*/`
#include <fog_pars_vertex>

precision highp float;

attribute vec3 color;
attribute float angle;
attribute float radius;

varying vec4 mvPosition;

varying vec3 _color;
varying vec3 _position;

varying float _radius;

uniform float scale;
uniform float time;
uniform float maxRadius;
uniform float media_adjustment;

void main(){
    _position = position;
    _radius = radius/maxRadius;

    float _angle = angle * (time) / (_radius * 25.0);
    _position.x = cos(_angle) * radius;
    _position.z = sin(_angle) * radius;

    vec4 localPosition = vec4(_position,1.0);
    vec4 worldPosition = modelMatrix * localPosition;
    vec4 viewPosition = viewMatrix * worldPosition;
    vec4 clipPosition = projectionMatrix * viewPosition;
    float distance = length(viewPosition.xyz);
    _color = color;

    mvPosition = viewPosition;
    gl_PointSize = media_adjustment * (1.0 -_radius) * scale/distance;
    
    gl_Position = clipPosition;
    #include <fog_vertex>
}
`

const introPointsFragmentShader = /*glsl*/`
#include <fog_pars_fragment>
    
varying vec3 _color;
varying vec3 _position;
varying float _radius;
uniform float maxRadius;

void main(){
    float normalRadius = _radius/50.0;
    gl_FragColor = vec4(_color,1.0-_radius);
    #include <fog_fragment>
}

`



const shaderPoint = ShaderLib.points;
const adjustedSize = window.devicePixelRatio > 1 ? 2.0 : 1.0;

const t_uniform = {
    time: { value: 1.0 },
    maxRadius : {value : 50.0},
    media_adjustment: {value: adjustedSize}
    
}
const uniforms = UniformsUtils.merge([UniformsLib['fog'], UniformsUtils.clone(shaderPoint.uniforms), t_uniform]);
uniforms.scale.value = 350;
const introMat = new ShaderMaterial({
    uniforms: uniforms,
    defines: {
        USE_SIZEATTENUATION: ""
    },
    vertexShader: introPointVertexShader,
    fragmentShader: introPointsFragmentShader,
    fog: true,
    transparent: true,
})






export { introMat }