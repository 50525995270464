<template>
    <div>
        <main-page />
    </div>
</template>

<script>
import MainPage from '@/components/MainPage.vue'
    export default {
        components:{
            MainPage
        }
    }
</script>

<style scoped>

</style>