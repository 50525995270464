import { Vector3, Object3D, BufferGeometry } from "three";

function getRandomPointInside(cube) {
    const width = cube.parameters.width;
    const height = cube.parameters.height;
    const depth = cube.parameters.depth;

    const x = Math.random() * width - (width / 2);
    const y = Math.random() * height - (height / 2);
    const z = Math.random() * depth - (depth / 2);
    return new Vector3(x, y, z);
}

function getRandomPointsInside(cube, count = 1) {
    const randomPoints = [];
    for (let i = 0; i < count; i++) {
        randomPoints.push(getRandomPointInside(cube));
    }
    return randomPoints;
}

function randomInRange(min = 0, max = 1) {
    return (Math.random() * (max - min)) + min;
}

function randomSetFromArray(array, numFromArray = 1) {
    const subSet = [];
    for (let i = 0; i < numFromArray; i++) {
        subSet.push(array[Math.floor(Math.random() * array.length)]);
    }
    return subSet;
}

function randomUnitVector() {
    const x = Math.random() * 2 - 1;
    const y = Math.random() * 2 - 1;
    const z = Math.random() * 2 - 1;
    return new Vector3(x, y, z).normalize();
}

function quantizeLine(line, numberOfPoints = 5) {
    const points = [];
    for (let i = 0; i < numberOfPoints; i++) {
        const t = i / numberOfPoints;
        const point = line.centerPoint.clone().lerp(line.endPoint, t);
        points.push(point);
    }
    return points;
}



export { getRandomPointInside, getRandomPointsInside, randomInRange, randomSetFromArray, randomUnitVector, quantizeLine }