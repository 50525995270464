import { Mesh, Vector3 } from "three";
import { MeshSurfaceSampler } from "three/examples/jsm/math/MeshSurfaceSampler"


function generateEndPoints(geometry, numPairs = 5) {
    const endPoints = [];
    const mesh = new Mesh(geometry.toNonIndexed());
    const sampler = new MeshSurfaceSampler(mesh).build();

    for (let i = 0; i < numPairs; i++) {
        const startPoint = new Vector3();
        const endPoint = new Vector3();
        sampler.sample(startPoint);
        sampler.sample(endPoint);
        endPoints.push({ startPoint: startPoint, endPoint: endPoint });
    }
    return endPoints;
}

function generateSingleEndPoints(geometry, numPoints = 5) {
    const points = [];
    const mesh = new Mesh(geometry.toNonIndexed());
    const sampler = new MeshSurfaceSampler(mesh).build();

    for (let i = 0; i < numPoints; i++) {
        const point = new Vector3();
        sampler.sample(point);
        points.push(point);
    }
    return points;
}

export { generateEndPoints, generateSingleEndPoints }



