import { LineSegments, Mesh, BoxGeometry, LineDashedMaterial, EdgesGeometry, Vector3, Box3, Layers, MeshBasicMaterial } from "three";

class Universe {
  geometry;
  wireFrame;
  wireFrameMat;
  center = new Vector3();
  edgeSegments;
  dimensions = new Vector3();
  box;
  solid;

  constructor(size = 2) {
    this.geometry = new BoxGeometry(size, size, size);
    this.dimensions = new Vector3(size, size, size);
    this.wireFrame = new EdgesGeometry(this.geometry);
    this.wireFrameMat = new LineDashedMaterial({
      color: 'white',
      colorWrite: false,
      transparent: true,
      linewidth: 4,
      dashSize: 2,
      gapSize: 2,
      opacity: 0.9,
      depthWrite: false
    });
    const meshMat = new MeshBasicMaterial({
      transparent: true,
      depthWrite: false,
      colorWrite: false
    });

    this.solid = new Mesh(this.geometry, meshMat);
    


    this.edgeSegments = new LineSegments(this.wireFrame);
    this.edgeSegments.computeLineDistances();
    this.edgeSegments.material = this.wireFrameMat;
    this.center = this.geometry.center().clone();
    this.dimensions = new Vector3(this.geometry.parameters.width, this.geometry.parameters.height, this.geometry.parameters.depth);
    this.box = new Box3().setFromObject(this.edgeSegments);
  }

  getEdgeSegments() {
    return this.edgeSegments;
  }

}


export { Universe };




