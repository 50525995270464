import { BufferGeometry, BufferAttribute, Float32BufferAttribute, PointsMaterial, Vector3, Points, Sphere, SphereGeometry, MeshBasicMaterial, Mesh } from "three";
import { introMat } from "../shaders/introPointsShader";


class IntroPoints {

    constructor(particleCount = 100000, radius = 50) {
        this.radius = radius;
        this.introPoints = new Points();
        let sphereRadius = this.radius/10.0;
        let blackHoleSphere = new SphereGeometry(sphereRadius);
        this.blackHole = new Mesh(blackHoleSphere, new MeshBasicMaterial({color:'black', fog:false}));

        const introGeometry = new BufferGeometry();
        this.particleCount = particleCount;
        const verts = [];
        const angles = [];
        const radiusArray = [];
        for (let i = 0; i < particleCount; i++) {
            let newAngle = Math.random() * Math.PI * 2;
            let newRadius = Math.random() * 50;
            let position = new Vector3(
                Math.cos(newAngle) * newRadius,
                0,
                Math.sin(newAngle) * newRadius
            );
            verts.push(position.x, position.y, position.z);
            angles.push(newAngle);
            radiusArray.push(newRadius);
        }

        introGeometry.setAttribute('position', new Float32BufferAttribute(verts, 3));
        introGeometry.setAttribute('angle', new Float32BufferAttribute(angles, 1));
        introGeometry.setAttribute('radius', new Float32BufferAttribute(radiusArray, 1));

        this.introPoints.geometry = introGeometry;
        this.introPoints.material = introMat;
        introMat.uniforms.maxRadius.value = this.radius;
      
        this.introPoints.tick = (delta) => {       
            introMat.uniforms.time.value = performance.now()/1000 + 100;
        }
    }
}

export { IntroPoints }