<template>
    <div id="games-page">
        <div id="game-words">
            <h3>
                Garden Games
            </h3>
            <p>
                Garden Games is a small indie game dev group casually working on small games focused on
                procedural generation, physics, and accessibility.
                <br />
                _______
                <br /><br />
                Mostly working in Unity.
                <br />
                _______
                <br /><br />
                Games include: small golf + <i> Katamari Damaci</i> game about cleaning up space junk,
                roguelite procedural cave exploring survival horror, and several game jam projects
                <br />
                _______
                <br /><br />
                Link coming soon.
            </p>
        </div>
        <div id="games-container">

        </div>
    </div>
</template>

<script>
import { BasicViewPort } from '@/World/components/basicView';
import { UnityLogo } from '@/World/models/unity'
import { MeshBasicMaterial, Mesh, Vector3 } from "three"

export default {
    name: "GardenGames",
    mounted() {
        const container = document.querySelector('#games-container');
        this.resizeView(container);
        this.viewPort = new BasicViewPort(container);
        const unityLogo = UnityLogo().then(
            (obj) => {
                const unity = obj.children[0];
                const unityObj = new Mesh(unity.geometry, new MeshBasicMaterial());
                unityObj.scale.set(.15, .15, .15);
                unityObj.position.set(0, -.5, 0);
                this.viewPort.addToScene(unityObj);
            }
        )
        this.viewPort.start();
        window.addEventListener('resize', this.resizeView(container));
    },
    methods: {
        resizeView(container) {
            let parentContainer = document.querySelector('#games');
            let word = document.querySelector("#game-words");
            let canvasContainer = container;
            let parentHeight = parentContainer.offsetHeight;
            let desiredHeight = (parentHeight - word.offsetHeight) * .9; // should be minus padding etc.
            canvasContainer.style.height = desiredHeight + 'px';
        }
    }

}
</script>

<style scoped>
#games-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h3 {
    display: inline-block;
    border-bottom: 2px solid white;
    width: 90%;
}

p {
    font-family: 'Share Tech Mono', monospace;
    padding-left: 10px;
    padding-right: 10px;
}

#games-container {
    border-top: 2px solid white;
    width: 100%;
}
</style>