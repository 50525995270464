import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MainPage from '../views/MainPageView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/main',
    alias: ['/proto', '/about-me', '/garden-games'],
    name: 'main',
    component: MainPage

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve((to, from, next) => {
  if (from.name != "home" && to.name != "home") {
    router.app.$emit('random-point');
  }
  next();
});

export default router
