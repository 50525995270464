<template>
  <div id="scene-container">
    <button v-show="onFront" @click="descend()" id="descend-button"> Descend</button>
  </div>
</template>



<script>
import { World } from "@/World/World.js";
import { gsap } from "gsap";

export default {
  name: "HelloWorld",
  data() {
    return {
      world: "Could not create world",
      onFront: true
    }
  },
  mounted() {
    const container = document.querySelector("#scene-container");
    this.world = new World(container);
    this.world.start();
    this.world.generateUniverse();
  },

  methods: {
    descend() {
      this.world.descend();
      let tl = gsap.timeline();
      tl.add(() => {
        this.onFront = false;
      }, 0.33)
    }
  }
};
</script>

<style scoped>

#scene-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

#descend-button {
  top: 37.5%;
  left: 50%;
  border-color: white;
}



</style>
