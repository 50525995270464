<template>
    <div id="about-me">
        <div id="about-words">
            <h3>
                About Me
            </h3>
            <p>
                Pittsburgh based Full Stack Developer and Food Scientist with creative background.<br /><br />
                Currently assisting teaching full stack web development with SpringBoot, .NET, PostgreSQL, MsSQL, and
                Vue.js.<br /><br />
                Expertise and research in craft chocolate and coffee.
                <br />
                _______
                <br /><br />
                Building games with Garden Games in Unity.<br />
                Building audio hardware and mid-scale manufacturing projects with Proto Cooperative.
                <br />
                _______
                <br /><br />
                Part time interested in: Arduino • Unity • three.js • robotics
                <br />
                _______
                <br /><br />
                Full time goblin.<br /><br />
                Sort of obsessed with cosmology.
            </p>
        </div>
        <div id="view-container">
            <!-- Contains Small Scene-->
        </div>
    </div>
</template>

<script>
import { BasicViewPort } from "@/World/components/basicView";
import { Face } from "@/World/models/face"
import { Mesh, PointsMaterial, MeshBasicMaterial } from "three";

export default {
    name: "AboutMe",
    data() {
        return {
            viewPort: "Failed To Load"
        }
    },
    mounted() {
        const container = document.querySelector('#view-container');
        this.resizeView(container);
        this.viewPort = new BasicViewPort(container);
        const face = Face().then(
            (obj) => {
                const faceObj = obj.children[0];
                const facePoints = new Mesh(faceObj.geometry, new MeshBasicMaterial({ size: 0.1, color: 'white', wireframe: true }));
                facePoints.scale.set(.5, .5, .5);
                this.viewPort.addToScene(facePoints);
            }
        )
        this.viewPort.start();
        window.addEventListener('resize', this.resizeView(container));
    },

    methods: {
        resizeView(container) {
            let parentContainer = document.querySelector('#about-me');
            let word = document.querySelector("#about-words");
            let canvasContainer = container;
            let parentHeight = parentContainer.offsetHeight;
            let desiredHeight = (parentHeight - word.offsetHeight) * .9;
            canvasContainer.style.height = desiredHeight + 'px';
        }
    }

}
</script>

<style scoped>
#about-me {
    display: flex;
    flex-direction: column;
    align-items: center;

}

h3 {
    display: inline-block;
    border-bottom: 2px solid white;
    width: 90%;

}

p {
    font-family: 'Share Tech Mono', monospace;
    padding-left: 10px;
    padding-right: 10px;
}

#view-container {
    border-top: 2px solid white;
    padding-bottom: 50px;
    width: 100%;
}
</style>