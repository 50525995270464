import { Sphere } from "three";

function generateNodeLines(points, checkRadius = 5) {
    const nodeStrings = [];
    points.forEach(point => {
        let checkSphere = new Sphere(point, checkRadius);
        const pointsInSphere = [];
        points.forEach(checkPoint => {
            if (checkSphere.containsPoint(checkPoint) && point != checkPoint) {
                pointsInSphere.push(checkPoint);
            }
        })

        pointsInSphere.forEach(foundPoint => {
            nodeStrings.push({ centerPoint: point, endPoint: foundPoint });
        })
    })
    return nodeStrings;
}

export { generateNodeLines }