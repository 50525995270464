import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";



function createOrbitControls(camera, domElement) {
  const controller = new OrbitControls(camera, domElement);

  controller.enableDamping = true;
  controller.autoRotate = false;
  controller.enableRotate = true;
  controller.enableZoom = false;
  controller.autoRotateSpeed = 1.0;
  controller.enablePan = false;

  controller.tick = (delta) => {
    controller.update();
  }

  return controller;
}





export { createOrbitControls }
