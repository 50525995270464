<template>
  <div id="app">
    <router-view />
    <div id="nav-bar" @focus="blink($event)">
      <a v-show="$route.name != 'home'" href="" @focus="blink($event)" @click.prevent="pushAboutMe($event)"
        id="about-me-nav">
        about.me</a>
      <a v-show="$route.name != 'home'" href="" @focus="blink($event)" @click.prevent="pushProto($event)" id="proto-nav">
        proto.co-op</a>
      <a v-show="$route.name != 'home'" href="" @focus="blink($event)" @click.prevent="pushGarden($event)" id="games-nav">
        garden.games</a>
      <a><br></a>
      <a id="github-link" href="https://github.com/PatrickDolanJ">github</a>
      <a id="my-name" href="" @focus="blink($event)" @click.prevent="pushMain"> patrick.dolan.2023</a>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { Arduino } from "./World/models/arduino";
import { Points, PointsMaterial, Vector3 } from 'three'

export default {
  mounted() {
    document.title = "patrick.dolan";
    this.loadModels();
  },
  methods: {
    blink(event) {
      gsap.delayedCall(0.66, () => { event.target.blur() });
    },
    pushMain(event) {
      if (this.$route.name != 'home' && this.$route.path != '/main') {
        this.$router.push('/main');
      } 
    },
    pushAboutMe() {
      if (this.$route.path != '/about-me') {
        this.$router.push('/about-me');
      }
    },
    pushProto(event) {
      if (this.$route.path != "/proto") {
        this.$router.push('/proto');
      }
    },
    pushGarden(event) {
      if (this.$route.path != "/garden-games") {
        this.$router.push('/garden-games');
      }
    },

     loadModels() {
      const arduino = Arduino().then(
        (obj) => {
          const arduino = obj.children[0];
          const arduinoPoints = new Points(arduino.geometry, new PointsMaterial({ size: 0.01, color: 'white' }));
          arduinoPoints.setRotationFromAxisAngle(new Vector3(1, 0, 0), 1.5708);
          arduinoPoints.scale.set(.05, .05, .05);
        }
      )

    }
  }
}
</script>


<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #212123;
}

#scene-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #212123;
}

button {
  font-family: 'Fira Code', monospace;
  background-color: transparent;
  opacity: 60%;
  color: white;
  position: absolute;
  width: 130px;
  transform: translate(-50%, -50%);
  border-color: white;
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  padding: 10px;
  text-align: center;
  z-index: 1;
}

a:focus,
button:focus {
  animation: blinker 0.11s linear infinite;
}



@keyframes blinker {
  50% {
    opacity: 0;
  }
}

button:hover {
  opacity: 80%;
}

#nav-bar {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  bottom: 2%;
  left: 1.5%;
}

#my-name {
  z-index: 1;
  color: white;
  font-family: 'Share Tech Mono', monospace;
  opacity: 80%;
}

#github-link {
  color: red;
}

a {
  display: inline-block;
  z-index: 1;
  color: white;
  font-family: 'Share Tech Mono', monospace;
  opacity: 80%;
  width: fit-content;
}
</style>

