import { createCamera, rideCameraOnPath } from "./components/camera";
import { Universe } from "./components/cube";
import { createScene } from "./components/scene";
import { createRenderer } from "./system/renderer";
import { Resizer } from "./system/Resizer";
import { Loop } from "./system/Loop";
import { createPoints } from "./components/pointRenderer"
import { Object3D, Color, Group, MeshBasicMaterial, Sphere, Mesh, Vector3, SphereGeometry, CircleGeometry, PlaneGeometry, Frustum, Matrix4 } from 'three'

import { generateNodeLines } from './components/nodeLinker'
import { getRandomPointsInside, randomInRange, quantizeLine, randomSetFromArray } from "./system/helpers";
import { NodeClusters } from "./components/nodeCluster";
import { mergeGalaxies } from "./components/galaxyMerger";
import { createOrbitControls } from "./components/orbitController";
import { generateSingleEndPoints } from "./components/endPointGenerator";
import { galaxyMat } from "./shaders/galaxyShader";
import { IntroPoints } from "@/World/components/introPoints";
import { gsap } from "gsap";
import router from "../router/index.js";
import store from "@/store/index"
import { GalaxyHighlighter } from "./components/galaxyHighLighter";
import { Text } from "troika-three-text";
import { preloadFont } from "troika-three-text";


let renderer;
let camera;
let scene;
let loop;
let controls;
let universe;
let cube;
let box;
let introPoints;
let introBlackhole;
let nodePositions;
let nodes;
let currentHighlight;

//----------------------Settings---------------------

// Size of Universe Cube  
const universeSize = 100;

// Distance to start applying fog
const fogStartDistance = 100;

// Distance at which fog is fog is opaque
const fogEndDistance = 355;

const backgroundColor = new Color().setRGB(0.13, 0.13, 0.14);
const fogColor = new Color().setRGB(0.20, 0.16, 0.25);

// Main color of galaxies, lerp to galaxyDim color
const galaxyBrightColor = new Color('white');
const galaxyDimColor = new Color("rgb(255,235, 255)");

// Maximum Node radius at which main nodes spawn galaxies in  
const maximumNodeSize = 17;

// Maximum count of galaxies that a node can have
const maximumGalaxyCount = 200;

// How many nodes to spawn interally
const nodeQuantity = 1000;

// How Many nodes to spawn on the faces of the cube 
const faceNodesCount = 20;

// How many background galaxies to spawn
const backgroundGalaxyCount = 9000;

// How many quantized points between nodes
const nodeLinkCheckRadius = 16;

// Minimum  and Maximum amount of nodes to spwan along node lines
const minLinkNodes = 2;
const maxLinkNodes = 4;

// How many galaxies to spawn in link galaxies
const maxLinkGalaxyCount = 5;
const maxLinkGalaxyRadius = 1;

// Function to capture camera move event
//and scale the fog by how far the camera is from the cubes position
function onChange() {
  const camPos = camera.position.clone();
  const distance = camPos.distanceTo(this.target);
  const fogValue = (0.75 * distance) + 50;
  scene.fog.far = fogValue;
}

class World {
  constructor(container) {
    //setup basic three components
    scene = createScene(backgroundColor, fogColor, fogStartDistance, fogEndDistance);
    camera = createCamera(container);
    renderer = createRenderer();
    container.append(renderer.domElement);
    loop = new Loop(camera, scene, renderer);

    // Setup orbit controlls
    controls = createOrbitControls(camera, renderer.domElement);
    controls.addEventListener('change', onChange);
    const camPos = camera.position.clone();
    const distance = camPos.distanceTo(controls.target);
    const fogValue = distance + 50;
    scene.fog.far = fogValue;
    scene.fog.near = 40;

    // Setup Landing BlackHole
    const intro = new IntroPoints();
    introPoints = intro.introPoints;
    introBlackhole = intro.blackHole;
    controls.target = introPoints.position;

    // Create Universe cube componentspo
    universe = new Universe(universeSize);
    cube = universe.getEdgeSegments();
    box = universe.box;

    //Preload fonts
    preloadFont(
      {
        font: 'ShareTechMono-Regular.ttf',
        characters : 'NGC1234567890',
      },
      ()=>{
        
      }
    )
    scene.add(introPoints);
    introPoints.add(introBlackhole);
    loop.updatables.push(controls, introPoints);
    loop.hoverables.push(universe.solid);


    const resizer = new Resizer(container, camera, renderer);
  }

  render() {
    renderer.render(scene, camera);
  }

  generateUniverse() {
    while (cube.children.length) {
      cube.remove(cube.children[0]);
    }

    // Generate Objects
    nodePositions = getRandomPointsInside(universe.geometry, nodeQuantity);
    const faceNodes = generateSingleEndPoints(universe.geometry, faceNodesCount);
    const backGroundGalaxies = getRandomPointsInside(universe.geometry, backgroundGalaxyCount);
    nodePositions.push(...faceNodes);

    // WebNodes
    nodes = new Group();
    const nodeGeometries = [];
    const nodeMaterials = [];

    nodePositions.forEach(point => {
      const radius = randomInRange(0, maximumNodeSize);
      const numOfGalaxies = randomInRange(0, maximumGalaxyCount);
      const newNode = new NodeClusters(box, point, numOfGalaxies, radius, 1, galaxyBrightColor, galaxyDimColor);
      const galaxies = newNode.galaxies;
      nodeGeometries.push(newNode.nodeGeometry);
      nodeMaterials.push(newNode.nodeMaterial)
      nodes.add(galaxies);
    })

    //Node Links
    const nodeLines = generateNodeLines(nodePositions, nodeLinkCheckRadius);
    const linkPoints = [];
    nodeLines.forEach(line => {
      const numLineGalaxies = randomInRange(minLinkNodes, maxLinkNodes);
      linkPoints.push(...quantizeLine(line, numLineGalaxies));
    })

    linkPoints.forEach(point => {
      const radius = randomInRange(0, maxLinkGalaxyRadius);
      const numOfGalaxies = randomInRange(0, maxLinkGalaxyCount);
      const newNode = new NodeClusters(box, point, numOfGalaxies, radius, 1, galaxyBrightColor, galaxyDimColor);
      nodes.add(newNode.galaxies);
      nodeGeometries.push(newNode.nodeGeometry);
    })

    //generating Renderable Points
    const randomPointToRender = createPoints(backGroundGalaxies, galaxyMat);
    const mergedGalaxies = mergeGalaxies(nodeGeometries);

    // Add objects to Universe
    cube.add(randomPointToRender);
    cube.add(mergedGalaxies);
    store.commit('setUniverse', cube)
  }

  start() {
    loop.start();
  }
  stop() {
    loop.stop();
  }

  setScene(_scene) {
    scene = _scene;
  }

  setUniverse(universe) {
    cube = universe;
  }

  descend() {
    const tl = gsap.timeline();
    controls.enableRotate = false;
    tl.to(camera.position, {
      x: 0,
      z: 0,
      y: 8,
      duration: 5,
      ease: "power3.in"
    }).to(introBlackhole.material.color, {
      r: backgroundColor.r,
      g: backgroundColor.g,
      b: backgroundColor.b,
      duration: 0.1,
      ease: "linear"
    }, 5.0)
    tl.add(moveFromIntroToMain)
  }

  goToPoint() {
    if (nodePositions == undefined || nodePositions == null) {
      this.generateUniverse();
    }
    if (nodePositions.length > 0) {
      const randPosition = nodePositions[Math.floor(Math.random() * nodePositions.length)];
      const timeline = gsap.timeline();

      timeline.to(controls.target, {
        x: randPosition.x,
        y: randPosition.y,
        z: randPosition.z,
        duration: 1.5,
        ease: "power2"
      }).then(
        this.getCloseByGalaxies
      )

    }
  }

  getCloseByGalaxies() {
    if (currentHighlight != undefined) {
      currentHighlight.removeFromParent();
    }

    const pointsInSphere = [];
    camera.updateProjectionMatrix();
    camera.updateMatrixWorld();

    const frustum = new Frustum().setFromProjectionMatrix(
      new Matrix4().multiplyMatrices(
        camera.projectionMatrix,
        camera.matrixWorldInverse
      )
    );

    nodePositions.forEach(point => {
      if (frustum.containsPoint(cube.localToWorld(point.clone()))) {
        pointsInSphere.push(point);
      }
    })
    const randomGalaxyCluster = randomSetFromArray(pointsInSphere, 1);

    if (randomGalaxyCluster[0]) {
      const newPosition = randomGalaxyCluster[0];
      currentHighlight = new GalaxyHighlighter(newPosition, camera);
      const label = new Text();
      const randNum = Math.floor(Math.random() * 9999);
      label.text = 'NGC ' + randNum;
      label.font = "ShareTechMono-Regular.ttf";
      label.fontSize = 1;
      label.material.fog = false;
      label.material.opacity = 0.75;
      label.position.x = newPosition.x;
      label.position.y = newPosition.y +3;
      label.position.z = newPosition.z;
      label.gpuAccelerateSDF = true;

      label.tick = (delta) =>{
        label.lookAt(camera.position);
        label.rotation.setFromQuaternion(camera.quaternion);
      }
      cube.add(label);
      label.sync();

      cube.add(currentHighlight);
      loop.updatables.push(currentHighlight,label)
      nodePositions = nodePositions.filter((point)=>{
        return point.x != randomGalaxyCluster[0].x && point.y != randomGalaxyCluster[0].y && point.z != randomGalaxyCluster[0].z
      })
    }
  }

  setMainPage() {
    scene.add(universe.solid, cube);
    scene.remove(introPoints);
    controls.target = cube.position;
    camera.position.set(0, 0, 100);
    let tl = gsap.timeline();
    tl.to(camera.position, {
      x: 0,
      y: 0,
      z: 50,
      duration: 1.5,
      ease: "power4"
    });
    tl.add(() => {
      controls.autoRotate = true;
      controls.enableRotate = true;
      controls.enableZoom = true;
    });
  }
}

function moveFromIntroToMain() {
  router.push('/main');
}

export { World };

