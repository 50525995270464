import { BufferGeometry, Points, PointsMaterial } from "three";

let defaultMaterial = new PointsMaterial(
    {
        color: 'white'
    }
)

function createPoints(points = [], material = defaultMaterial) {
    const geometry = new BufferGeometry();
    geometry.setFromPoints(points);
    const pointsToRender = new Points(geometry, material);
    return pointsToRender;
}

export { createPoints }