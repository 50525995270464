import { PlaneGeometry, Mesh, Vector3, MeshBasicMaterial, SphereGeometry } from "three";


function createHiggsDiagram() {
    const planeGeo = new PlaneGeometry(20, 20, 15, 15);
    const plane = new Mesh(planeGeo, new MeshBasicMaterial({ wireframe: true }));
    plane.rotateOnAxis(new Vector3(1, 0, 0), 1.5708);
    let vertices = planeGeo.attributes.position.array;
    let minimummVec = new Vector3(vertices[0], vertices[1], vertices[2]);

    for (let i = 1; i < vertices.length; i++) {
        if (i % 3 == 2) {
            const x2 = Math.pow(vertices[i - 2], 2);
            const y2 = Math.pow(vertices[i - 1], 2) + 1;
            let a = .012;
            const b2 = Math.pow(.1, 2);
            const c2 = Math.pow(3, 2);
            vertices[i] -= a * (1 - (x2 + y2) / b2) * Math.pow(2.71828, -(x2 + y2) / (2 * c2));
            if (vertices[i] > minimummVec.z) {
                minimummVec = new Vector3(vertices[i - 2], vertices[i - 1], vertices[i]);
            }
        }
    }

    planeGeo.attributes.position.needsUpdate = true;

    const sphereGeo = new SphereGeometry(.6);
    const localMinimum = new Mesh(sphereGeo, new MeshBasicMaterial({ color: 'red', depthTest: false }));

    localMinimum.position.set(minimummVec.x, minimummVec.y, minimummVec.z);
    plane.add(localMinimum);

    return plane;

}

export { createHiggsDiagram }