<template>
    <div @random-point="randomPoint($event)" id="main-container">
        <div id="scene-container"></div>
        <about-me id="about-me" v-if="$route.path == '/about-me'"></about-me>
        <proto-coop id="proto" v-if="$route.path == '/proto'"></proto-coop>
        <garden-games id="games" v-if="$route.path == '/garden-games'"></garden-games>
        <galaxy-info v-if="$route.path != '/main'"></galaxy-info>
    </div>
</template>

<script>
import { World } from '@/World/World';
import { gsap } from 'gsap';
import AboutMe from '@/components/AboutMe.vue'
import ProtoCoop from './ProtoCoop.vue';
import GardenGames from './GardenGames.vue';
import GalaxyInfo from './GalaxyInfo.vue';

export default {
    name: "MainPage",
    components: {
        AboutMe,
        ProtoCoop,
        GardenGames,
        GalaxyInfo
    },
    data() {
        return {
            world: "Failed To Load"
        }
    },
    mounted() {
        this.$root.$on('random-point', this.randomPoint);
        const container = document.querySelector("#scene-container");
        this.world = new World(container);
        this.world.start();
        if (this.$store.getters.getUniverse != null) {
            this.world.setUniverse(this.$store.getters.getUniverse);
        } else {
            this.world.generateUniverse();
        }
        this.world.setMainPage();
    },
    beforeDestroy() {
        this.$root.$off('random-point', this.randomPoint);
    },
    methods: {
        randomPoint(event) {

            gsap.delayedCall(.33, () => {
                if (event) {
                    event.target.blur();
                }
                this.world.goToPoint();
            })
        }
    }
}
</script>

<style scoped>
#about-me,
#proto,
#games {
    font-family: 'Fira Code', monospace;
    opacity: 60%;
    background-color: #212123;
    border-color: white;
    border-radius: 2px;
    border-style: solid;
    border-width: 2px;
    color: white;
}

#about-me:hover,
#proto:hover,
#games:hover {
    opacity: 80%;
}

#galaxy-info-page {
    position: absolute;
    top: 5vh;
    left: 2vw;
    border-width: 2px;
    opacity: .6;
    border-style: solid;
    border-color: white;
    width: 20vw;
    height: fit-content;
    z-index: 1;
}

/* DESKTOP */
@media (min-width: 991px) {

    #about-me,
    #games,
    #proto {
        position: absolute;
        /*transform: translate(-50%, -50%);*/
        top: 5vh;
        right: 2vw;
        font-size: 14px;
        height: 85vh;
        width: 20%;
    }

}

/* MOBILE */
@media (max-width: 992px) and (min-width: 376px) {

    #about-me,
    #games,
    #proto {
        display: flex;
        flex-direction: column;
        background-color: #212123;
        position: absolute;
        top: 2.5vh;
        left: 7.5vw;
        height: 80vh;
        font-size: 12px;
        width: 85vw;
        z-index: 0;
    }

    #galaxy-info-page {
        display: none;
    }
}

@media (max-width: 375px) {

    #about-me,
    #games,
    #proto {
        display: flex;
        flex-direction: column;
        background-color: #212123;
        position: absolute;
        top: 2.5vh;
        left: 7.5vw;
        height: 80vh;
        font-size: 12px;
        width: 85vw;
        z-index: 1;
    }

    #galaxy-info-page {
        display: none;
    }
}
</style>
