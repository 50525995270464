import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

async function Arduino() {
    return new Promise((resolve, reject) => {
        const loader = new GLTFLoader();
        loader.load('arduino.glb', function (gltf) {
            const arduino = gltf.scene;
            resolve(arduino);
        }, undefined, function (error) {
            console.error(error);
        });
       

    })

}

export { Arduino }