import { Clock, Raycaster, Vector2 } from "three";
const clock = new Clock();
const mouse = new Vector2(1, 1);
let hoverRaycaster;
let clickRaycaster;
let _camera;
let _scene;

class Loop {
  updatables;
  hoverables = [];
  clickables = [];

  constructor(camera, scene, renderer) {
    this.camera = camera;
    _camera = camera;
    this.scene = scene;
    _scene = scene;
    this.renderer = renderer;
    this.updatables = [];

    //Layer for hoverables
    hoverRaycaster = new Raycaster();
    hoverRaycaster.layers.set(2);
    hoverRaycaster.params.includeInvisible = true;

    //Layer for clickable
    clickRaycaster = new Raycaster();
    clickRaycaster.layers.set(4);
    clickRaycaster.params.includeInvisible = true;

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('click', onClick);
  }

  start() {
    this.renderer.setAnimationLoop(() => {
      this.tick();
      this.renderer.render(this.scene, this.camera);
    });
  }

  stop() {
    this.renderer.setAnimationLoop(null);
  }

  tick() {
    const delta = clock.getDelta();
    for (const object of this.updatables) {
      object.tick(delta);
    }
    onHover(mouse, this.camera, this.scene);
  }
}

function onMouseMove(event) {

  event.preventDefault();
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
}

function onHover(mouse, camera, scene) {
  hoverRaycaster.setFromCamera(mouse, camera);
  const intersection = hoverRaycaster.intersectObjects(scene.children);
  if (intersection.length > 0) {
    intersection.forEach(item => {
      item.object.onHover();
    })
  }
}
function onClick() {
  clickRaycaster.setFromCamera(mouse, _camera);
  const intersection = clickRaycaster.intersectObjects(_scene.children);
  if (intersection.length > 0) {
    intersection.forEach(item => {
      item.object.onClick();
    })
  }
}

export { Loop };
