import { CircleGeometry, Group,LineDashedMaterial ,Vector3, EdgesGeometry, LineSegments, LineBasicMaterial } from "three"
import {Text} from 'troika-three-text'

class GalaxyHighlighter{
    constructor(center, camera){
        const highLight = new Group();
        const radius = 1.5;
        const innerCircleGeo = new CircleGeometry(radius,20);
        const innerCircleEdge = new EdgesGeometry(innerCircleGeo);
        const innerCircleMat = new LineBasicMaterial({color:'white', fog:false, depthWrite: false,transparent: true, opacity: 0.75})
        const innerCircle = new LineSegments(innerCircleEdge, innerCircleMat);
        
        const outerCircleGeo = new CircleGeometry(radius*1.2,20);
        const outerCircleEdge = new EdgesGeometry(outerCircleGeo);
        const outerCicrleMat = new LineDashedMaterial({fog:false,dashSize:1.0,gapSize:.8,color:'white',depthWrite: false,transparent: true, opacity: 0.75});
        const outerCircle = new LineSegments(outerCircleEdge,outerCicrleMat);
      
        outerCircle.computeLineDistances();
        highLight.add(innerCircle,outerCircle);
        highLight.position.set(center.x,center.y,center.z);
        highLight.tick = (delta) => {
            highLight.lookAt(camera.position)
            highLight.rotateOnAxis(new Vector3(0,0,1),performance.now()/1000);
        }
        return highLight;
    }


}

export {GalaxyHighlighter}



